import { LocaleTypes } from "types";

export const HEADER_HEIGHT = 72;
export const FOOTER_HEIGHT = 56;

export const ROWS_IN_TABLE = [5, 10, 25, 50, 100];

export const locales: LocaleTypes[] = ["en", "pt", "ar"];
export const LOCALES_MAP = {
  en: { flag: "GB", text: "English", muiLocale: "enUS" },
  pt: { flag: "PT", text: "Portuguese", muiLocale: "ptPT" },
  ar: { flag: "SA", text: "Arabic", muiLocale: "arEG" },
};

export const publicFolderPath = process.env.PUBLIC_URL;

export const PAYMENT_TYPES = [
  {
  label: "Domestic Payments",
  value:"domestic-payment-consents"
  },
  {
  label: "Domestic Scheduled Payments",
  value:"domestic-scheduled-payment-consents"
  },
  {
   label: "Domestic SO Payments",
   value:"domestic-standing-order-consents"
  },
  {
   label: "Domestic Vrp Payments",
   value:"domestic-vrp-consents"
   },
   {
     label: "International Payments",
     value:"international-payment-consents"
   },
   {
     label: "International Scheduled Payments",
     value:"international-scheduled"
   },
   {
     label: "International SO Payments",
     value:"international-standing-order-consents"
   }
];

export const timeFormat = "dd MMM yyyy HH:mm";

export const isCynergy = (tenantName: string) => tenantName?.startsWith("cynergybank")
export const isReliance = (tenantName: string) => tenantName?.startsWith("reliancebank")
export const isConsumerBank = (tenantName: string) => tenantName === "consumerbank-jpmorgan";
export const isAUB = (tenantName: string) => tenantName === "aub";
export const isSBI = (tenantName: string) => tenantName === "sbi";

export const entityOptions = [
  {
    label: "GB Retail",
    value: "GBRTL",
  },
  {
    label: "GB Business",
    value: "GBBUS",
  },
  {
    label: "GB Wholesale",
    value: "GBWHL",
  },
  {
    label: "BE Retail",
    value: "BERTL",
  },
  {
    label: "BE Business",
    value: "BEBUS",
  },
  {
    label: "DE Retail",
    value: "DERTL",
  },
  {
    label: "DE Business",
    value: "DEBUS",
  },
];
