import axios, { AxiosInstance } from "axios";
import { QueryFunctionContext } from "react-query";

import { getSession } from "helpers/sessionToken";
import { getEnvVarsStorage, randomNum } from "helpers";
import { ConsentData, IAxiosBaseContext, QueryKeys } from "types";

export const sortConsents = (list: ConsentData[]) =>{
  let result = list?.filter(Boolean)
  return result?.sort((a, b) => new Date(b.CreationDateTime).getTime() - new Date(a.CreationDateTime).getTime());
}

export const axiosRequests = (instance: AxiosInstance) => {
  return {
    exchange: async code => {
      const { data } = await instance.post("/exchange", { code });
      return data;
    },
    refreshToken: async refresh_token => {
      const { data } = await instance.post("/refresh", { refresh_token });
      return data;
    },
    logOut: async tokens => {
      const { data } = await instance.post("/logout", tokens);
      return data;
    },
  } as IAxiosBaseContext;
};

export const getPsuConsents = async (context: QueryFunctionContext<[QueryKeys.CONSENT, string, number, number,string]>) => {
  const envs = getEnvVarsStorage();
  const entity = sessionStorage.getItem("entity") as string
  const headers = { authorization: `Bearer ${getSession()?.access_token}`, "ent-id": entity };

  const consentType = context.queryKey[1];
  const page = context.queryKey[2] || 1;
  const limit = context.queryKey[3] || 10;
  const paymentType = context.queryKey[4] || "domestic-payment-consents";
  if (consentType === envs?.obie?.apiPispConsents) {
   
    const { data } = await axios.get(`${consentType}/${paymentType}?pageIndex=${page-1}&pageSize=${limit}`, { headers });
    const sortedResults= {
      data: sortConsents(data?.content),
      total : data?.totalElements
    }
    return sortedResults;
  }

  const { data } = await axios.get(`${consentType}-psu?pageIndex=${page-1}&pageSize=${limit}`, { headers });
  const sortedResults= {
    data: sortConsents(data?.content ?? []),
    total: data?.totalElements ?? 1
  }
  return sortedResults;
};

export const revokePsuConsents = async ({ consentType, ConsentId }: { consentType: string; ConsentId: string }) => {
  const envs = getEnvVarsStorage();
  if (consentType === envs?.obie?.apiPispConsents) {
    return;
  }

  const { data } = await axios.put(
    `${consentType}/${ConsentId}?consentStatus=revoke`,
    { body: { consentStatus: "revoke" } },
    {
      headers: { authorization: `Bearer ${getSession()?.access_token}` },
    },
  );
  return data;
};

export const revokeCbpiiConsents = async ({ consentType, ConsentId }: { consentType: string; ConsentId: string }) => {
  const envs = getEnvVarsStorage();
  if (consentType === envs?.obie?.apiPispConsents) {
    return;
  }

  const { data } = await axios.put(
    `${envs?.obie?.revokeCbpiiConsents}/${ConsentId}?status=revoke`,
    { body: { status: "revoke" } },
    {
      headers: { authorization: `Bearer ${getSession()?.access_token}` },
    },
  );
  return data;
};
export const revokePispConsents = async ({ paymentType, ConsentId }: { paymentType: string; ConsentId: string }) => {
  const envs = getEnvVarsStorage();
  // if (consentType === envs?.obie?.apiPispConsents) {
  //   return;
  // }
  let isVrp = paymentType === "domestic-vrp-consents" ? "domestic-vrps" : ""
  const { data } = await axios.put(
    `${envs?.obie?.revokePispConsents}/${isVrp}/${ConsentId}?status=revoke`,
    { body: { status: "revoke" } },
    {
      headers: { authorization: `Bearer ${getSession()?.access_token}` },
    },
  );
  return data;
};

export const getEnvVars = async () => {
  const { data } = await axios.get(`/api/env-vars`);
  return {
    ...data,
    defaultScopes: ["openid", "profile", "email"],
    redirectUri: `${data.hostUrl}/login`,
    obie: {
      apiAispConsents: `${data.bankingApi}/obie2-aisp/open-banking/v3.1/aisp/account-access-consents`,
      apiPispConsents: `${data.bankingApi}/obie2-pisp/open-banking/v3.1/pisp`,
      revokePispConsents: `${data.bankingApi}/obie2-pisp/admin/payments`,
      apiCbpiiConsents: `${data.bankingApi}/obie2-cbpii/open-banking/v3.1/cbpii/funds-confirmation-consents`,
      revokeCbpiiConsents: `${data.bankingApi}/obie2-cbpii/admin/consents`,
    },
    authUrl:
      `${data.authHost}/protocol/openid-connect/auth` +
      `?nonce=${randomNum(7)}` +
      `&client_id=${data.clientId}` +
      `&response_type=${data.authResponseType}` +
      `&redirect_uri=${data.hostUrl}/login` +
      `&scope=${data.authScope}`,
  };
};
