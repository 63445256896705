export enum QueryKeys {
  USER = "user",
  CONSENT = "aisp",
}

export enum DialogTypes {
  VIEW,
  REVOKE,
}

export enum AppRoutes {
  HOME = "/",
  LOGIN = "/login",
  PRIVACY = "/privacy-policy",
  TERMS = "/terms-and-conditions",
  USER = "/user/authorisations",
}

export interface IAxiosBaseContext {
  exchange: (code: string) => Promise<TokenExchangeData>;
  refreshToken: (refreshToken: string) => Promise<TokenExchangeData>;
  logOut: (tokens: Pick<SessionData, "id_token" | "refresh_token">) => Promise<unknown>;
}

export interface Account {
  SchemeName: string;
  Identification: string;
  Name: string;
  SecondaryIdentification?: string;
}

export interface Amount {
  Amount: string;
  Currency: string;
}

export interface ConsentData {
  ConsentId: string;
  ClientId: string;
  Status: string;
  organisationName: string;
  OrganisationName: string;
  CreationDateTime: string;
  ExpirationDateTime: string;
  Permissions?: string[];
  Accounts?: string[];
  StatusUpdateDateTime: string;
  TransactionFromDateTime: string;
  TransactionToDateTime: string;
  isRevoked: boolean;
  AccountId?: string;
  PsuId?: string;
  EntityId?: string;
  Type?: string;
  Initiation?: {
    InstructionIdentification: string;
    EndToEndIdentification: string;
    FirstPaymentAmount?: Amount;
    CreditorAccount: Account;
    DebtorAccount?: Account;
    InstructedAmount: Amount;
    RemittanceInformation: {
      Unstructured: string;
      Reference: string;
    };
    RequestedExecutionDate?: string;
  };
  ControlParameters?: {
    ValidFromDateTime :string
    ValidToDateTime :string
    MaximumIndividualAmount : {
      Amount :string
      Currency :string
    },
    PeriodicLimits : [ {
      PeriodType  :string
      PeriodAlignment :string
      Amount :string
      Currency  :string
    } ],
  },
}

export type ConsentResponseData = {
  data : ConsentData[],
  total : number
}

export interface TokenExchangeData {
  id_token: string;
  access_token: string;
  refresh_token: string;
  timestamp: number;
}

export interface ParsedToken {
  acr: string;
  "allowed-origins": string[];
  azp: string;
  email_verified: boolean;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  preferred_username: string;
  scope: string;
  session_state: string;
  sid: string;
  sub: string;
  typ: string;
  user_id: string;
  username: string;
}

export interface SessionData extends TokenExchangeData {
  timestamp: number;
}

export type LocaleTypes = "en" | "pt" | "ar";
